<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span class="registry_fields_label">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
      <el-input v-model="localValue"
                :size="size"
                v-mask="mask"
                :placeholder="placeholder || $locale.main.placeholder.string_field"
                v-on:input="!emitOnEnter ? $emit('input', $event) : null" :name="name" :readonly="readonly"
                @keyup.enter.native="emitOnEnter ? $emit('input', localValue) : null"
      ></el-input>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
export default {
  name: 'a-string',
  mixins: [mixin, registryMixin],
  props: {
    name: {
      type: String,
      description: 'Атрибут'
    },
    label: {
      description: 'Название',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    emitOnEnter: {
      description: 'Изменять по Enter',
      type: Boolean,
      default: false
    },
    mask: {
      description: 'Маска',
      type: String,
      default: null,
      options: {
        tooltip: {
          show: true,
          content: `# - Числа (0-9)<br>
                    A - Латиница (a-z,A-Z)<br>
                    N - Числа и латиница (a-z,A-Z,0-9)<br>
                    X - Любой символ<br>
                    Я - Кириллица (a-я,A-Я)`
        }
      }
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
